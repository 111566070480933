<template>
  <div class="introb1">
    <div class="introb1__wrapper">
      <div class="introb1__content">
        <h1 class="introb1__h1 h1 h1--white">
          French with a twist!
        </h1>
        <p class="p p--white introb1__p">
          Raffinierte französische Küche trifft auf feine Asia-Aromen. Moderne
          Interpretationen, gekocht mit Leib und Seele und den köstlichsten
          Zutaten der Saison. Passend dazu: die fulminante und handverlesene
          Weinkarte unseres Sommeliers.
        </p>
        <div class="introb1__boxbutton">
          <AppButton
            class="introb1__btn"
            :link="'/assets/karte/thi_bistrokarte.pdf'"
            >Bistro</AppButton
          >
          <AppButton
            class="introb1__btn"
            :link="'/assets/karte/thi_getaenkekarte.pdf'"
            >Getränke</AppButton
          >
     
          <AppButton class="introb1__btn" :link="'/assets/karte/thi_speisekarte.pdf'"
            >Speisekarte</AppButton
          >
          <AppButton class="introb1__btn" :link="'/assets/karte/thi_weinkarte.pdf'"
            >Wein</AppButton
          >
        </div>
        <div class="introb1__logo">
          <TheLogo />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheLogo from "@/components/ui/logo/TheLogo.vue";
import AppButton from "../../ui/buttons/AppButton.vue";
export default {
  components: { TheLogo, AppButton },
};
</script>

<style lang="scss"></style>
